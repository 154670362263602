import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/MainLayout');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/auscultations/',
            name: 'AscalationPage',
            component: () => import(/* webpackChunkName: "home" */'../views/diagnosis/ascalation/AscalationPage')
        },
        {
            path: '/blood-chemistry/',
            name: 'BloodChemistry',
            component: () => import(/* webpackChunkName: "home" */'../views/diagnosis/blood-chemistry/BloodChemistryPage')
        },
        {
            path: '/clinical-signs/',
            name: 'ClinicalSigns',
            component: () => import(/* webpackChunkName: "home" */'../views/diagnosis/clinical-signs/ClinicalSignsPage')
        },
        {
            path: '/complete-blood-count/',
            name: 'CompleteBloodCount',
            component: () => import(/* webpackChunkName: "home" */'../views/diagnosis/complete-blood-count/CompleteBloodCountPage')
        },
        {
            path: '/normal-values/',
            name: 'NormalValues',
            component: () => import(/* webpackChunkName: "home" */'../views/diagnosis/normal-values/NormalValuesPage')
        },
        {
            path: '/pathological-findings/',
            name: 'PathologicalFindings',
            component: () => import(/* webpackChunkName: "home" */'../views/diagnosis/pathological-findings/PathologicalFindingsPage')
        },
        {
            path: '/specific-tests/',
            name: 'SpecificTests',
            component: () => import(/* webpackChunkName: "home" */'../views/diagnosis/specific-tests/SpecificTestsPage')
        },
        {
            path: '/urine-analysis/',
            name: 'UrineAnalysis',
            component: () => import(/* webpackChunkName: "home" */'../views/diagnosis/urine-anlaysis/UrineAnalysisPage')
        }
    ]
};
